@import './variables.scss';
#contact-us {
  padding: 40px 0;
  .subheading {
    text-align: center;
  }
  .contact-grid {
    margin: 24px 0;
    display: grid;
    gap: 24px;
  }
  .form-input {
    background-color: transparent;
    padding: 20px;
    width: 100%;
    color: white;
    border-radius: 20px;
    border: 3px solid white;
  }
  .form-input::placeholder {
    color: white;
  }
  .submit {
    justify-self: flex-end;
    width: 100%;
    padding: 16px 40px;
    color: #fff;
    border-radius: 140px;
    background-color: $black;
  }
  .line {
    display: none;
  }
  .poof {
    width: 100%;
  }
  @media (min-width: 1024px) {
    padding: 0;

    .contact-form {
      display: flex;
      flex-direction: column;
    }
    .contact-grid {
      grid-template-columns: 1fr 1fr;
    }

    .comment {
      grid-row: 2;
      grid-column: 1/3;
    }
    .submit {
      align-self: center;
      margin: 0 auto;
    }
  }

  @media (min-width: 1280px) {
  }
}
