@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Luckiest Guy", sans-serif !important;
  background: black;
}

.font-mont {
  font-family: "Montserrat", sans-serif;
}

.font-skia {
  font-family: "PeaceSans", sans-serif;
}

.my-container {
  width: 100%;
  padding: 0 40px;
  max-width: 864px;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .my-container {
    padding: 0;
  }
}

@media (min-width: 1280px) {
  .my-container {
    max-width: 1080px;
  }
}

.text-stroke-sm {
  text-shadow: rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px,
    rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px,
    rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px,
    rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px,
    rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px,
    rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px,
    rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px,
    rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px,
    rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px,
    rgb(0, 0, 0) 2.88051px -0.838247px 0px;
}