.footer-dino {
  position: absolute;
}
.last {
  width: 8%;
  left: 90%;
  top: 17%;
}
.sixth {
  width: 8%;
  left: 77%;
  top: 27%;
}
.fifth {
  width: 8%;
  left: 62%;
  top: 46%;
}

.fourth {
  width: 7%;
  left: 48%;
  top: 50%;
}

.first {
  width: 7%;
  left: 2%;
  top: 45%;
}
.second {
  width: 7%;
  left: 14%;
  top: 65%;
}
.third {
  width: 8%;
  left: 31%;
  top: 63%;
}
