@import "./variables.scss";
#about-us {
  .heading {
    position: relative;
    text-align: center;
    z-index: 4;
    .map {
      min-width: max-content;
      right: 50%;
    }
  }
  .subheading {
    max-width: 825px;
  }
  .pic {
    display: block;
    width: 55%;
    z-index: 10;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    bottom: 8px;
  }
  .pic-template {
    width: 100%;
    height: 0;
    padding-bottom: 120%;
    position: relative;
  }
  @media (min-width: 1024px) {
    .heading {
      .map {
        min-width: max-content;
        right: 0%;
        transform: translateX(0);
      }
    }
  }
}
