@import "./variables.scss";
.app {
  background: $turf;
  .btn {
    box-sizing: border-box;
    display: block;
    text-align: center;
    // width: 100%;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    padding: 9px 13px !important;
    color: #fff;
    background-color: $orange;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    border: 3px solid transparent;
    min-width: max-content;
    box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.5);
  }
  .btn:hover {
    background: $khaki;
    border: 3px solid $khaki;
    transform: translateX(-1px) translateY(-1px) scale(1.02);
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 24px 55px -13px rgba(0, 0, 0, 0.4);
  }
  .btn-entrepot {
    font-family: "Luckiest Guy", sans-serif;
    font-size: 20px;
    padding: 8px 12px !important;
    width: 100%;
    @media (min-width: 1024px) {
      padding: 12px 24px !important;
      font-size: 36px;
    }
  }
  .btn-discord {
    padding-left: 8px !important;
    padding-right: 8px !important;
    background: #3a3a3a;
  }
  .btn-mint {
    background: $orange;
  }
  .btn-mint:hover {
    background: $khaki;
  }
  .outlined {
    box-sizing: border-box;
    border: 3px solid white;
    border-radius: 50px;
    background: transparent;
  }
  .ghost {
    position: absolute;
    right: 10%;
    top: 700px;
    width: 20%;
    z-index: 10;
  }

  .anchor {
    // font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    min-width: max-content;
    text-shadow: 0px 1px 4px #29220e;
    @apply cursor-pointer  font-bold  transform transition-all;
  }
  .anchor:hover {
    color: $orange;
    transform: scale(1.1);
  }
  .socials-icon {
    display: block;
    color: white;
    transition: all 0.5s ease-in-out;
  }
  .socials-icon:hover {
    color: $orange;
  }

  .special-grid {
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 40px;
  }
  .shadow-show {
    text-shadow: inherit;
    transition: all 0.5s;
  }
  .shadow-none {
    text-shadow: none;
  }
  .orange-btn {
    background: linear-gradient(180deg, #ff9400 0%, #cc7703 100%);
    border: 5px solid #9b6418;
  }
  .yellow-btn {
    background: linear-gradient(180deg, #e7b628 0%, #a9892c 100%);
    border: 5px solid #a58115;
  }
  .green-btn {
    background: linear-gradient(180deg, #739a3a 0%, #5b7c29 100%);
    border: 5px solid #486819;
  }
  .turf-btn {
    background: linear-gradient(180deg, #bfad75 0%, #988857 100%);
    border: 5px solid #8a7c4e;
  }
  .brown-btn {
    background: linear-gradient(180deg, #8c7d4e 0%, #6f6033 100%);
    border: 5px solid #645422;
  }
  .darkBrown-btn {
    background: linear-gradient(180deg, #665b39 0%, #3a321b 100%);
    border: 5px solid #29220e;
  }
  .about-section {
    .top-gradient {
      background: linear-gradient(180deg, rgba(191, 172, 112, 1), rgba(191, 172, 112, 0));
    }
    .bottom-gradient {
      background: linear-gradient(0deg, rgba(191, 172, 112, 1), rgba(191, 172, 112, 0));
    }
  }
  .orange-btn-sm {
    background: linear-gradient(180deg, #ff9400 0%, #cc7703 100%);
    border: 2px solid #9b6418;
  }
  .yellow-btn-sm {
    background: linear-gradient(180deg, #e7b628 0%, #a9892c 100%);
    border: 2px solid #a58115;
  }
  @media (min-width: 1024px) {
    .special-grid {
      gap: 40px;
      grid-template-columns: 1.5fr 1.5fr;
    }
  }

  @media (min-width: 1280px) {
    .special-grid {
      gap: 80px;
      grid-template-columns: 1.1fr 1.5fr;
    }
  }
}
