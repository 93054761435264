@import "./variables.scss";
#faq {
  padding-top: 40px;
  .my-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .subheading {
    text-align: center;
    padding-bottom: 40px;
  }
  .faq-question {
    cursor: pointer;
    font-size: 12px;
    text-align: left;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 16px 30px;
    border-radius: 20px;
    // background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%239C8D5EFF' stroke-width='4' stroke-dasharray='20%2c 5' stroke-dashoffset='12' stroke-linecap='round'/%3e%3c/svg%3e");
    // border: 3px dashed #9c8d5e;
  }
  .faq-question-heading {
    width: 90%;
    position: relative;
    top: 4px;
  }
  .arrow-down {
    padding-left: 4px;
    transition: all 0.4s;
  }

  @media (min-width: 1024px) {
    padding-top: 0px;
    .faq-question {
      font-size: 20px;
    }
  }

  @media (min-width: 1280px) {
    .faq-question {
      font-size: 16px;
    }
  }
}
