@import "./variables.scss";
.popup {
  width: 95%;
  max-width: 564px;
  background: $khaki;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .popup-mint {
    width: 100%;
  }
  .popup-mint-form {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }
  .popup-mint-item {
    border-radius: 40px;
    width: 100%;
    height: 64px;
    align-items: center;
    padding: 0 30px;
    border: 3px solid $orange;
    display: flex;
    color: white;
    line-height: 1;
  }
  .popup-mint-button {
    color: white;
    background: $orange;
    border: none;
    justify-content: center;
  }
  .popup-close {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    line-height: 1;
    background: $orange;
    color: white;
    font-weight: 500;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bomb {
    @apply absolute w-1/6 top-0 left-3/4;
  }
  @media (min-width: 1024px) {
    padding: 40px;
    .popup-close {
      width: 40px;
      height: 40px;
    }
    .bomb {
      top: 5%;
    }
    .popup-close {
      font-size: 16px;
    }
  }

  @media (min-width: 1280px) {
  }
}
