@import './variables.scss';

.heading {
  position: relative;
  z-index: auto;
  color: white;
  font-size: 32px;
  line-height: 1.1;
  transition: all 0.3s;
  padding-bottom: 8px;

  text-shadow: rgb(0, 0, 0) 5px 0px 0px, rgb(0, 0, 0) 4.90033px 0.993347px 0px,
    rgb(0, 0, 0) 4.60531px 1.94709px 0px, rgb(0, 0, 0) 4.12668px 2.82321px 0px,
    rgb(0, 0, 0) 3.48353px 3.58678px 0px, rgb(0, 0, 0) 2.70151px 4.20736px 0px,
    rgb(0, 0, 0) 1.81179px 4.6602px 0px, rgb(0, 0, 0) 0.849836px 4.92725px 0px,
    rgb(0, 0, 0) -0.145998px 4.99787px 0px,
    rgb(0, 0, 0) -1.13601px 4.86924px 0px, rgb(0, 0, 0) -2.08073px 4.54649px 0px,
    rgb(0, 0, 0) -2.94251px 4.04248px 0px, rgb(0, 0, 0) -3.68697px 3.37732px 0px,
    rgb(0, 0, 0) -4.28444px 2.57751px 0px, rgb(0, 0, 0) -4.71111px 1.67494px 0px,
    rgb(0, 0, 0) -4.94996px 0.7056px 0px,
    rgb(0, 0, 0) -4.99147px -0.291871px 0px,
    rgb(0, 0, 0) -4.83399px -1.27771px 0px,
    rgb(0, 0, 0) -4.48379px -2.2126px 0px,
    rgb(0, 0, 0) -3.95484px -3.05929px 0px,
    rgb(0, 0, 0) -3.26822px -3.78401px 0px,
    rgb(0, 0, 0) -2.4513px -4.35788px 0px,
    rgb(0, 0, 0) -1.53666px -4.75801px 0px,
    rgb(0, 0, 0) -0.560763px -4.96845px 0px,
    rgb(0, 0, 0) 0.437495px -4.98082px 0px,
    rgb(0, 0, 0) 1.41831px -4.79462px 0px, rgb(0, 0, 0) 2.34258px -4.41727px 0px,
    rgb(0, 0, 0) 3.17346px -3.86382px 0px, rgb(0, 0, 0) 3.87783px -3.15633px 0px,
    rgb(0, 0, 0) 4.4276px -2.32301px 0px, rgb(0, 0, 0) 4.80085px -1.39708px 0px,
    rgb(0, 0, 0) 4.98271px -0.415447px 0px;

  .accent {
    color: $orange;
  }
  .map {
    text-shadow: none;
    color: $orange;
    right: 0;
  }
  @media (min-width: 1024px) {
    font-size: 60px;
    text-shadow: rgb(58, 58, 58) 12px 0px 0px,
      rgb(58, 58, 58) 11.9584px 0.998843px 0px,
      rgb(58, 58, 58) 11.8337px 1.99075px 0px,
      rgb(58, 58, 58) 11.6269px 2.96885px 0px,
      rgb(58, 58, 58) 11.3395px 3.92634px 0px,
      rgb(58, 58, 58) 10.9733px 4.85657px 0px,
      rgb(58, 58, 58) 10.531px 5.75311px 0px,
      rgb(58, 58, 58) 10.0156px 6.60971px 0px,
      rgb(58, 58, 58) 9.43065px 7.42044px 0px,
      rgb(58, 58, 58) 8.78027px 8.17967px 0px,
      rgb(58, 58, 58) 8.06895px 8.88212px 0px,
      rgb(58, 58, 58) 7.30163px 9.52293px 0px,
      rgb(58, 58, 58) 6.48363px 10.0977px 0px,
      rgb(58, 58, 58) 5.62063px 10.6023px 0px,
      rgb(58, 58, 58) 4.71862px 11.0333px 0px,
      rgb(58, 58, 58) 3.78387px 11.3878px 0px,
      rgb(58, 58, 58) 2.82285px 11.6633px 0px,
      rgb(58, 58, 58) 1.84224px 11.8577px 0px,
      rgb(58, 58, 58) 0.848846px 11.9699px 0px,
      rgb(58, 58, 58) -0.15044px 11.9991px 0px,
      rgb(58, 58, 58) -1.14868px 11.9449px 0px,
      rgb(58, 58, 58) -2.13895px 11.8078px 0px,
      rgb(58, 58, 58) -3.11438px 11.5888px 0px,
      rgb(58, 58, 58) -4.06819px 11.2894px 0px,
      rgb(58, 58, 58) -4.99376px 10.9116px 0px,
      rgb(58, 58, 58) -5.88468px 10.458px 0px,
      rgb(58, 58, 58) -6.73475px 9.93192px 0px,
      rgb(58, 58, 58) -7.53808px 9.33688px 0px,
      rgb(58, 58, 58) -8.2891px 8.67703px 0px,
      rgb(58, 58, 58) -8.98258px 7.95696px 0px,
      rgb(58, 58, 58) -9.61372px 7.18167px 0px,
      rgb(58, 58, 58) -10.1781px 6.35653px 0px,
      rgb(58, 58, 58) -10.6719px 5.48727px 0px,
      rgb(58, 58, 58) -11.0916px 4.57993px 0px,
      rgb(58, 58, 58) -11.4344px 3.64081px 0px,
      rgb(58, 58, 58) -11.6977px 2.67641px 0px,
      rgb(58, 58, 58) -11.8799px 1.69344px 0px,
      rgb(58, 58, 58) -11.9796px 0.698716px 0px,
      rgb(58, 58, 58) -11.9962px -0.300857px 0px,
      rgb(58, 58, 58) -11.9296px -1.29834px 0px,
      rgb(58, 58, 58) -11.7801px -2.28682px 0px,
      rgb(58, 58, 58) -11.5489px -3.25942px 0px,
      rgb(58, 58, 58) -11.2375px -4.2094px 0px,
      rgb(58, 58, 58) -10.8481px -5.13016px 0px,
      rgb(58, 58, 58) -10.3834px -6.01532px 0px,
      rgb(58, 58, 58) -9.84671px -6.85874px 0px,
      rgb(58, 58, 58) -9.24164px -7.65454px 0px,
      rgb(58, 58, 58) -8.57243px -8.39723px 0px,
      rgb(58, 58, 58) -7.84372px -9.08163px 0px,
      rgb(58, 58, 58) -7.06058px -9.703px 0px,
      rgb(58, 58, 58) -6.22843px -10.257px 0px,
      rgb(58, 58, 58) -5.35305px -10.7399px 0px,
      rgb(58, 58, 58) -4.44052px -11.1482px 0px,
      rgb(58, 58, 58) -3.49717px -11.4791px 0px,
      rgb(58, 58, 58) -2.52955px -11.7304px 0px,
      rgb(58, 58, 58) -1.54437px -11.9002px 0px,
      rgb(58, 58, 58) -0.548477px -11.9875px 0px,
      rgb(58, 58, 58) 0.451226px -11.9915px 0px,
      rgb(58, 58, 58) 1.4478px -11.9123px 0px,
      rgb(58, 58, 58) 2.43432px -11.7505px 0px,
      rgb(58, 58, 58) 3.40395px -11.5071px 0px,
      rgb(58, 58, 58) 4.34995px -11.1838px 0px,
      rgb(58, 58, 58) 5.26576px -10.7829px 0px,
      rgb(58, 58, 58) 6.14503px -10.3072px 0px,
      rgb(58, 58, 58) 6.98164px -9.75995px 0px,
      rgb(58, 58, 58) 7.7698px -9.14495px 0px,
      rgb(58, 58, 58) 8.50404px -8.46648px 0px,
      rgb(58, 58, 58) 9.17925px -7.72925px 0px,
      rgb(58, 58, 58) 9.79076px -6.93838px 0px,
      rgb(58, 58, 58) 10.3343px -6.09935px 0px,
      rgb(58, 58, 58) 10.8061px -5.21799px 0px,
      rgb(58, 58, 58) 11.203px -4.30041px 0px,
      rgb(58, 58, 58) 11.522px -3.35299px 0px,
      rgb(58, 58, 58) 11.7612px -2.38229px 0px,
      rgb(58, 58, 58) 11.9186px -1.39506px 0px,
      rgb(58, 58, 58) 11.9934px -0.398151px 0px;
  }
  @media (min-width: 1280px) {
    font-size: 58px;
  }
}
