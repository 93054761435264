@import "./variables.scss";
.modal-menu {
  background: $khaki;
  z-index: 20;
  width: 100vw;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 40px;
  padding-top: 122px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1024px) {
    display: none;
  }

  @media (min-width: 1280px) {
  }
}
