@import './variables.scss';
.subheading {
  position: relative;
  z-index: auto;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 29px;
  text-align: left;

  @media (min-width: 1024px) {
    text-align: left;
    font-size: 14px;
    line-height: 28px;
  }
}
