.get-to-know {
  padding-top: 40px;
  .text-stroke-sm {
    text-shadow: rgb(125, 103, 32) 3px 0px 0px, rgb(125, 103, 32) 2.83487px 0.981584px 0px,
      rgb(125, 103, 32) 2.35766px 1.85511px 0px, rgb(125, 103, 32) 1.62091px 2.52441px 0px,
      rgb(125, 103, 32) 0.705713px 2.91581px 0px, rgb(125, 103, 32) -0.287171px 2.98622px 0px,
      rgb(125, 103, 32) -1.24844px 2.72789px 0px, rgb(125, 103, 32) -2.07227px 2.16926px 0px,
      rgb(125, 103, 32) -2.66798px 1.37182px 0px, rgb(125, 103, 32) -2.96998px 0.42336px 0px,
      rgb(125, 103, 32) -2.94502px -0.571704px 0px, rgb(125, 103, 32) -2.59586px -1.50383px 0px,
      rgb(125, 103, 32) -1.96093px -2.27041px 0px, rgb(125, 103, 32) -1.11013px -2.78704px 0px,
      rgb(125, 103, 32) -0.137119px -2.99686px 0px, rgb(125, 103, 32) 0.850987px -2.87677px 0px,
      rgb(125, 103, 32) 1.74541px -2.43999px 0px, rgb(125, 103, 32) 2.44769px -1.73459px 0px,
      rgb(125, 103, 32) 2.88051px -0.838247px 0px;
    &.picked {
      text-shadow: rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px,
        rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px,
        rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px,
        rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px,
        rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px,
        rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px,
        rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px,
        rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px,
        rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px,
        rgb(0, 0, 0) 2.88051px -0.838247px 0px;
    }
  }
  .rarity-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 24px;
    line-height: 1;
    text-shadow: rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px,
      rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px,
      rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px,
      rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px,
      rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px,
      rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px,
      rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px,
      rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px,
      rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px,
      rgb(0, 0, 0) 2.88051px -0.838247px 0px;
  }
  .nft-pic {
    transition: all 0.3s;

    &:hover {
      &.skew-left {
        transform: rotate(-5deg) scale(0.95);
      }
      &.skew-right {
        transform: rotate(5deg) scale(0.95);
      }
    }
  }
  .elemental {
    img {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }
  .pic-main {
    position: relative;
    height: 142px;
    transition: all 0.3s;
    &:hover {
      z-index: 999;
      transform: scale(1.2);
    }
  }
  .rarity-pic {
    position: absolute;
    top: 0;
    height: 100%;
    width: auto;
    transition: all 0.3s;
    &:hover {
      z-index: 999;
      transform: scale(1.2);
    }
  }
  .divider {
    width: 100%;
    opacity: 0.3;
    height: 2px;
    background: #fff;
  }
  .legend {
    position: absolute;
    left: 50%;
    bottom: 0%;
    transform: translateX(-50%) translateY(55%);
    color: white;
    font-size: 16px;
  }
  .commons,
  .albino {
    color: white;
    p {
      span {
        position: relative;
        top: 8px;
      }
    }
  }
  p {
    span {
      position: relative;
      top: 8px;
    }
  }
  .commons {
    color: #6fcd93;
  }
  .elementals {
    color: #e7b628;
  }
  .ultra-rares {
    color: #e8a1ff;
  }
  .legendaries {
    color: #c63693;
  }
  @media (min-width: 768px) {
    padding-top: 80px;
    .pic-main {
      width: 24%;
      max-height: 120px;
    }
  }
  @media (min-width: 1024px) {
    .rarity-box {
      display: grid;
      grid-template-columns: 200px 1fr 100px;
      font-size: 32px;
    }
  }
}
